
$(document).on('turbolinks:load', function () {
  // console.log('>>> modal.js');

  if( $('.iziModal').length > 0 ){
    /* iziModal のコンテンツがある */
    // console.log('>>> has iziModal content');
    // $('.modalBtn').on('click', function(e){
      $(".iziModal").iziModal({
        width: '607px', // ちょうど、600px で使えるようにするため（この値は max-width として使われる）, SP は _ModalWrap.scss で設定している
        borderBottom: false,
        overlayColor: 'rgba(0, 0, 0, 0.8)',
        headerColor: 'transparent',
        background: 'transparent',
        transitionIn: 'fadeInUp',
        transitionOut: 'fadeOutDown',
        iframeHeight:'100px',
        focusInput:false,
        closeButton: true,
        overlayClose: false,
        onOpening: function(modal){
          // console.log('>>> iziModal onOpening !');

          $(document).on('change', 'input:radio', function() {
            let radio = $(this).val();
            let name = $(this).data('eval-attr');
            let $starsWrap = $( '.ModalWrap__stars.' + '-' + name );
            // console.log('>>> attr name: ' + name);
            // console.log('>>> radio val: ' + radio);
            // console.log('>>> star no: ' + radio.slice(-1));
            let no = Number( radio.slice(-1) ); // 末尾の１文字（star の番号）
            let hoverClass = '-hover';

            // クリックした star より小さい star を追加する
            for ( let i = 0; i < no; i++ ) {
              let s = 's' + ( i + 1 );
              $starsWrap
                .addClass(s)
                .removeClass(hoverClass)
            }

            // クリックした star より大きい star を削除する
            for ( let i = 5; no < i; i-- ) {
              let s = 's' + i;
              // console.log('s: ' + s);
              $starsWrap
                .removeClass(s)
                .removeClass(hoverClass)
            }
          });
          // end input:radio change

          // PC only
          if(isPc()){

            // hover
            $(document).on('mouseenter', '.ModalWrap__star', function(e) {
              let $radio = $(this).find('input:radio')
              let name = $radio.data('eval-attr');
              let $starsWrap = $( '.ModalWrap__stars.' + '-' + name );
              let no = parseInt($radio.val().slice(-1));
              let hoverClass = '-hover';
              // console.log(name, no);

              // hoverした star より小さい star を追加する
              for ( let i = 0; i < no; i++ ) {
                let s = 's' + ( i + 1 );
                $starsWrap
                  .addClass(hoverClass)
                  .addClass(s)
              }
              // hoverした star より大きい star を削除する
              for ( let i = 5; no < i; i-- ) {
                let s = 's' + i;
                // console.log('s: ' + s);
                $starsWrap
                  .addClass(hoverClass)
                  .removeClass(s)
              }

            });
            // out
            $(document).on('mouseleave', '.ModalWrap__star', function(e) {
              let $radio = $(this).find('input:radio')
              let name = $radio.data('eval-attr');
              let $starsWrap = $( '.ModalWrap__stars.' + '-' + name );
              // let no = parseInt($radio.val().slice(-1));
              let hoverClass = '-hover';

              let selectedVal = $("input[data-eval-attr='" + name + "']:checked").val();
              let no = 0;
              if(selectedVal){
                no = parseInt(selectedVal.slice(-1));
              }

              // console.log(name, selectedVal, no);

              // hoverした star より小さい star を追加する
              for ( let i = 0; i < no; i++ ) {
                let s = 's' + ( i + 1 );
                $starsWrap
                  .addClass(s)
                  .removeClass(hoverClass)
              }
              // hoverした star より大きい star を削除する
              for ( let i = 5; no < i; i-- ) {
                let s = 's' + i;
                // console.log('s: ' + s);
                $starsWrap
                  .removeClass(s)
                  .removeClass(hoverClass)
              }

            });
          }
        }
      });
    // end .iziModal


    // モーダルに表示するコンテンツを取得（"data-id" に紐づいている ）
    // $('#modal').iziModal('setContent', '<p>Example</p>');
    // $('.modalBtn').on('click', function(e){
      //var btnId = $(this).data('id');
      //console.log( btnId );
      //var item = $('#modalData li.ModalData__list').eq( btnId-1 ).html();
      // console.log( item );
      // $(".iziModal").iziModal('setContent', item);
      // $(".iziModal").iziModal();
    // });
  }
  // end iziModal のコンテンツがある
});
